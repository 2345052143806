var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "차트" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
              [
                _c("apexchart", {
                  ref: "chart1",
                  attrs: {
                    height: "250",
                    type: "bar",
                    width: _vm.chart1.chartWidth,
                    options: _vm.chart1.chartOptions,
                    series: _vm.chart1.series,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("apexchart", {
                  ref: "chart2",
                  attrs: {
                    height: "250",
                    type: "bar",
                    width: _vm.chart2.chartWidth,
                    options: _vm.chart2.chartOptions,
                    series: _vm.chart2.series,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-tab", {
        staticClass: "q-mt-sm",
        attrs: {
          type: "tabcard",
          align: "left",
          inlineLabel: true,
          tabItems: _vm.tabItems,
        },
        on: { tabClick: _vm.tabClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c("c-table", {
                  ref: "table",
                  attrs: {
                    title: "[" + tab.label + "] 개선 목록",
                    tableId: "kras01",
                    columns: _vm.grid.columns,
                    data: _vm.grid.data,
                  },
                  on: { linkClick: _vm.linkClick },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }